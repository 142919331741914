import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const RegistMassyo = () => {
  const data = useStaticQuery(graphql`
    query {
      massyo: file(relativePath: { eq: "loan.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `住宅ローン等の不動産担保融資を完済されると、銀行等から抵当権等の担保を抹消するするための書類をもらえます。
その書類で、管轄の法務局に登記申請をしなければ、登記上、担保権がついたままとなりますのでご注意ください。
弊所では、オンライン申請により日本全国対応可能となっておりますので、ご不明な点がございましたら、お気軽にご相談ください。
`

  return (
    <ServiceContent
      name={"regist__massyo"}
      title={"抵当権抹消"}
      content={content}
      fluid={data.massyo.childImageSharp.fluid}
      link={"/erase/"}
    />
  )
}

export default RegistMassyo
