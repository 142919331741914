import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const RegistZouyo = () => {
  const data = useStaticQuery(graphql`
    query {
      gift: file(relativePath: { eq: "gift.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `よく、不動産の名義変更のお問い合わせをいただくことがありますが、不動産の名義変更をするためには、『原因』が必要となります。
例えば、相続対策として不動産を贈与される場合等に贈与を原因として不動産の名義の移転登記をします。
もちろんその場合、贈与税の課税対象となりますので、通常の贈与税の確定申告、または贈与税の特例の利用の確定申告が必要となります。
弊所では、提携税理士と連携を取りながらご相談いただくことも可能ですので、お気軽にご相談ください。
`

  return (
    <ServiceContent
      name={"regist__gift"}
      title={"贈与登記"}
      content={content}
      fluid={data.gift.childImageSharp.fluid}
      link={"/gift/"}
    />
  )
}

export default RegistZouyo
