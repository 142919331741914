import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import RegistMassyo from "../components/service/registMassyo"
import RegistMeihen from "../components/service/registMeihen"
import RegistZouyo from "../components/service/registZouyo"
import BackContact from "../components/backContact"

const Regist = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      loan: file(relativePath: { eq: "loan.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)
  const description =
    "住宅ローン完済による抵当権抹消登記や、住所、氏名変更による不動産登記の変更、生前対策としての不動産の贈与登記など、大阪の司法書士事務所の司法書士法人みつわ合同事務所がご紹介します。"
  return (
    <Layout>
      <SEO
        description={description}
        title="抵当権抹消　住所変更　氏名変更　贈与　不動産登記 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.loan.childImageSharp.original.src}
        pageimgw={data.loan.childImageSharp.original.width}
        pageimgh={data.loan.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"regist"}
          fluid={data.loan.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"抵当権抹消・住所変更・贈与概要"}
        />
        <section className="regist">
          <div className="regist__container">
            <h1 className="regist__title">抵当権抹消・住所変更・贈与登記</h1>
            <div className="regist__service__list">
              <RegistMassyo />
              <RegistMeihen />
              <RegistZouyo />
            </div>
          </div>
        </section>
        <BackContact link={"/"} linkName={"Topページ"} />
      </div>
    </Layout>
  )
}

export default Regist
