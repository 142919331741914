import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceContent from "./serviceContent"

const RegistMeihen = () => {
  const data = useStaticQuery(graphql`
    query {
      meihen: file(relativePath: { eq: "meihen.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const content = `不動産の名義人となった後、住所、氏名に変更があっても、自動的に不動産登記の住所や氏名が変更されることはありません。
住所や氏名の変更があった旨の登記申請をする必要があります。
登記を以前の住所・氏名のまま放置しておいて、なりすまし被害に遭い、勝手に不動産を売却されたというトラブルに巻き込まれないためにも、早めに変更登記を済ませておくことをおすすめします。
`

  return (
    <ServiceContent
      name={"regist__meihen"}
      title={"住所・氏名変更登記"}
      content={content}
      fluid={data.meihen.childImageSharp.fluid}
      link={"/addrchange/"}
    />
  )
}

export default RegistMeihen
